import React from "react";
import JSONData from "../../../content/content.json"
import Button from "../../elements/button/button";
import parse from 'html-react-parser';

export default function Intro() {
  return (
    <div className={'page-block page-block--intro'}>
      <div className="container">
        <h2>{parse(JSONData.intro.title)}</h2>

        <div className="button-wrapper">
          <Button options={{text: JSONData.intro.button, color: 'red', href: '#what-we-do'}}/>
          <br/>
          <br/>
          <Button options={{text: JSONData.shared.annualReport2023, color: 'yellow', href: 'pdfs/limelight-annual-report-2023.pdf'}}/>
        </div>
      </div>
    </div>
  )
}
