import * as React from "react";
import parse from 'html-react-parser';
import JSONData from "../content/content.json";

import Layout from "../components/layout/layout";
import Intro from "../components/page-blocks/intro/intro";
import WhoWeSupport from "../components/page-blocks/who-we-support/who-we-support";
import Button from "../components/elements/button/button";
import CarouselOrganisations from "../components/page-blocks/carousel-organisations/carousel-organisations";

const IndexPage = () => {
  return (
    <Layout>

      <Intro/>

      <CarouselOrganisations/>

      <div id={'what-we-do'} className={'header-sticky-offset'}>
        <div className={'page-block page-block--background-black'}>
          <div className="container">
            <div className="el--half-half">
              <h3>{parse(JSONData.whatWeDo.title)}</h3>
              <div className="el--half-half__inner">
                <div className="half">
                  {parse(JSONData.whatWeDo.text)}
                  <div className="el--half-half__buttons-stacked">
                    <Button
                      options={{
                        target: '_blank',
                        text: JSONData.whatWeDo.btn1,
                        color: 'red',
                        href: 'pdfs/limelight-annual-report-2023.pdf'
                      }}/>
                  </div>
                </div>
                <div className="half">
                  <img src={'/map-of-earth-in-ascii.png'} alt={'Map of earth in ascii'}
                       className={'el--half-half__img'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhoWeSupport/>

      <div className={'page-block page-block--background-lime-stone'}>
        <div className="container">
          <div className="el--quote">
            <img src={'/standing-half-letter-l.png'} alt={'Standing half letter L'} className={'el--quote__img'}/>
            <div className="el--quote__text">
              <h4 className={'el--quote__quote'}>&ldquo;{parse(JSONData.quote.quote)}&rdquo;</h4>
              <h5 className={'el--quote__person'}>&lt;{parse(JSONData.quote.person)}&gt;</h5>
            </div>
          </div>
        </div>
      </div>

      <div id={'how-we-work'} className={'header-sticky-offset'}>
        <div className={'page-block page-block--background-black'}>
          <div className="container">
            <div className="el--half-half">
              <div className="el--half-half__inner el--half-half__inner--no-row-gap">
                <div className="half">
                  <h3>{parse(JSONData.howWeWork.title)}</h3>
                </div>
                <div className="half">
                  {parse(JSONData.howWeWork.text)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id={'who-we-are'} className={'header-sticky-offset'}>
        <div className={'page-block page-block--background-dark-grey'}>
          <div className="container">
            <div className="el--third-third-third">
              <h3>{parse(JSONData.whoWeAre.title)}</h3>
              <p>{parse(JSONData.whoWeAre.text)}</p>
              <div className="el--third-third-third__inner">
                <div className="third">
                  <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeAre.block1.title)}&gt;</h4>
                  <p>{parse(JSONData.whoWeAre.block1.text)}</p>
                </div>
                <div className="third">
                  <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeAre.block2.title)}&gt;</h4>
                  <p>{parse(JSONData.whoWeAre.block2.text)}</p>
                </div>
                <div className="third">
                  <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeAre.block3.title)}&gt;</h4>
                  <p>{parse(JSONData.whoWeAre.block3.text)}</p>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="container team">*/}
          {/*  <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeAre.team.title)}&gt;</h4>*/}
          {/*  <div className={'members'}>*/}
          {/*    {JSONData.whoWeAre.team.members.map((member, index) => {*/}
          {/*      return <div key={index} className={'member'}>*/}
          {/*        <img src={'team/' + member.img} className={'image'} alt={member.name}/>*/}
          {/*        <span>{member.name}</span>*/}
          {/*      </div>*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
