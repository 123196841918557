import React from "react";
import JSONData from "../../../content/content.json"
import parse from "html-react-parser";

let activeSlide = 0;

const setButtonStates = () => {
  const btnPrev = document.getElementById('btn-prev');
  const btnNext = document.getElementById('btn-next');
  if (activeSlide === 0) {
    btnPrev.setAttribute('disabled', '');
    btnNext.removeAttribute('disabled');
    btnNext.classList.remove('is-disabled');
  } else if (activeSlide === (JSONData.whoWeSupport.causes.length - 1)) {
    btnPrev.removeAttribute('disabled');
    btnPrev.classList.remove('is-disabled');
    btnNext.setAttribute('disabled', '');
  } else {
    btnPrev.removeAttribute('disabled');
    btnPrev.classList.remove('is-disabled');
    btnNext.removeAttribute('disabled');
    btnNext.classList.remove('is-disabled');
  }
}

const onNextClick = () => {
  const nextSlide = document.getElementById(`cause-${activeSlide + 1}`);
  if (!nextSlide) return;
  activeSlide++;
  nextSlide.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
  setButtonStates();
}

const onPreviousClick = () => {
  const prevSlide = document.getElementById(`cause-${activeSlide - 1}`);
  if (!prevSlide) return;
  activeSlide--;
  prevSlide.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
  setButtonStates();
}

const sortedCauses = JSONData.whoWeSupport.causes.sort((a, b) => {
  return a.name.localeCompare(b.name, 'en');
});

export default function WhoWeSupport() {
  return (
    <div id={'who-we-support'} className={'header-sticky-offset'}>
      <div className={'page-block page-block--background-dark-grey page-block--who-we-support'}>
        <div className="container">

          <h3>{parse(JSONData.whoWeSupport.title)}</h3>

          {/* Header */}
          <div className="causes-table causes-table--header">
            <span className="cell cell--name cell--header">
              <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeSupport.columns.name)}&gt;</h4>
            </span>
            <span className="cell cell--duration cell--header">
              <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeSupport.columns.duration)}&gt;</h4>
            </span>
          </div>

          <div className="causes-rows-wrapper">
            <div className="causes-rows-inner">

              {sortedCauses.map((cause, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="causes-table causes-table--row" id={'cause-' + i}>

                      <span className={'cell cell--name'}>
                        <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeSupport.columns.name)}&gt;</h4>
                        <p>
                          {cause.url ? <a href={parse(cause.url)} target="_blank" rel="noreferrer">{parse(cause.name)}</a> : parse(cause.name)}
                        </p>
                      </span>

                      <span className={'cell cell--duration'}>
                        <h4 className={'sub-header'}>&lt;{parse(JSONData.whoWeSupport.columns.duration)}&gt;</h4>
                        <p>
                          <span className={'span'}>{parse(cause.duration.span)}</span> &nbsp;({cause.duration.months} {(cause.duration.months === 1) ? 'month' : 'months'})
                        </p>
                      </span>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>

          <div className="causes-navigation">
            <button title={'Previous'} className={'el--button el--button--red is-disabled'}
                    id='btn-prev' onClick={onPreviousClick}>&lt;</button>
            <button title={'Next'} className={'el--button el--button--red'}
                    id='btn-next' onClick={onNextClick}>&gt;</button>
          </div>

        </div>
      </div>
    </div>
  )
}
