import React from "react"
import parse from "html-react-parser";

export default function Button({options}) {
  return (
    <a className={`el--button el--button--${options.color}`}
       href={options.href} target={options.target ? options.target : '_self'}>
        <span className="arrow arrow--left">&lt;</span>
        <span>{parse(options.text)}</span>
        <span className="arrow arrow--right">&gt;</span>
    </a>
  )
}
