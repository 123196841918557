import React from "react";
import Slider from "react-slick";
import JSONData from "../../../content/content.json";

const settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
  cssEase: "ease-in",
  arrows: true
};

export default function CarouselOrganisations() {
  return (
    <div className={'page-block page-block--carousel-organisations'}>
      <Slider {...settings}>
        {JSONData.carouselOrganisations.filter(item => !item.isDisabled).map((item, index) => {
          return <div key={index} className={'slide'}>
            <div className="carousel-organisations-slide-inner">
              <div className="wrapper">
                {(item.copyrightTitle || item.copyrightAuthor) &&
                  <a className={'copyright'} href={item.url} target={'_blank'} rel={'noreferrer'}>
                    {(item.copyrightTitle) && <span className={'copyright-title'}>{item.copyrightTitle}</span>}
                    {(item.copyrightTitle && item.copyrightAuthor) && <span>&nbsp;-&nbsp;</span>}
                    {(item.copyrightAuthor) && <span className={'copyright-author'}>{item.copyrightAuthor}</span>}
                  </a>}
                {(item.logoImage) &&
                  <a className={`logo-wrapper logo-wrapper--${(item.logoHorizontalPosition ? item.logoHorizontalPosition : 'right')} logo-wrapper--${(item.logoVerticalPosition ? item.logoVerticalPosition : 'top')}`}
                     href={item.url} target={'_blank'} rel={'noreferrer'}>
                    <img src={`carousel/logos/${item.logoImage}`} className={'logo'} alt={item.logoAlt}/>
                  </a>}
              </div>

              <div className={'background-image'}
                   style={{backgroundImage: 'url(\'/carousel/background-images/' + item.backgroundImage + '\')', backgroundPosition: item.backgroundPosition ? item.backgroundPosition : 'center center'}}></div>
            </div>
          </div>
        })}
      </Slider>
    </div>
  )
}
